.cube {
    z-index: 99;
    position: absolute;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .cube-wrapper {
        background: getColor('body');
        border-radius: 14px;
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        width: 100%;

        .cube-app {
            flex: 1 0 auto;
        }
        .cube-pos {
            display: flex;
            margin: 0 auto 10px;
            align-items: center;
            .pos-dot {
                margin: 0 5px;
                content: '';
                width: 10px;
                height: 10px;
                border-radius: 100%;
                background: getColor('default');
                cursor: pointer;
                &.selected {
                    background: getColor('highlight');
                    width: 12px;
                    height: 12px;
                }
            }
        }
    }
    .cube-controls {
        margin: 15px;
        height: 50px;
        width: 20%;
        background: getColor('table');
        display: flex;
        justify-content: space-between;
        padding: 20px;
        border-radius: 10px;
        z-index: 2;

        button {
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 28px;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            color: getColor('default');
            border: none;
            background: transparent;
            outline: none;
            .delete {
                background-color: getColor('high-criticity');
            }
        }
    }
}
