@import url('//cdn.syncfusion.com/ej2/tailwind.css');
@import '@angular/cdk/overlay-prebuilt.css';

@import 'abstracts/variables';
@import 'abstracts/fonts';
@import 'abstracts/mixins';

@import 'vendors/echarts';
@import 'vendors/custocy';
@import 'vendors/materials';
@import 'vendors/gridster';
@import 'vendors/syncfusion';
@import 'vendors/apexcharts';
@import 'vendors/vasturiano';
@import 'vendors/shepherd';

@import 'components/icon';
@import 'components/cube';
@import 'components/cdk';
@import 'components/form';
@import 'components/button';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        font-family: 'Roboto', 'Helvetica Neue', sans-serif;
        @apply bg-background text-onbackground;
    }

    pre {
        font-family: 'Roboto' !important;
    }

    // ul {
    //     list-style: disc !important;
    //     list-style-position: inside !important;
    // }

    *:focus {
        @apply outline-none;
    }

    router-outlet+ng-component {
        flex: 1;
        max-height: 100%;
        display: flex;
        position: relative;
    }

    // ! IMPORATNT:
    // https://stackoverflow.com/questions/11126685/why-does-container-div-insist-on-being-slightly-larger-than-img-or-svg-content
    svg {
        display: block !important;
    }
}

@layer components {
    input[type='checkbox']::before {
        content: '';
        @apply h-3 w-3 bg-onhighlight;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        transform: scale(0);
        transform-origin: bottom left;
        transition: 120ms transform ease-in-out;
        /* Windows High Contrast Mode */
    }

    input[type='checkbox']:checked::before {
        transform: scale(1);
    }
}
