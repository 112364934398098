.echarts-custom-tooltip {
    padding: 0.25rem !important;

    div {
        font-size: 0.75rem !important;
        line-height: 1rem !important;
    }

    span {
        font-size: 0.75rem !important;
        line-height: 1rem !important;
    }
}