.icon {
    @include size($iconSize);
    top: 0px;
    position: relative;

    @each $class, $size in $iconSizes {
        &.#{$class} {
            @include size($size);
        }
    }

    @each $class, $url, $rotation in $iconList {
        &.#{$class} {
            @include iconmask($url);
            @if $rotation {
                transform: rotate($rotation);
            }
        }
    }
}
