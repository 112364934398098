.force-graph-container {
    .graph-tooltip {
        @apply text-onbackground bg-background border border-nonecol rounded-lg p-0 min-w-64 #{!important};
    }
}

.circlepack-viz {
    .node {
        &.default-font {
            .path-label {
                font-family: 'Roboto' !important;
            }
        }

        .path-label {
            font-family: 'assets' !important;
            opacity: 1 !important;
            @apply fill-onsurface #{!important};
        }
        &.font-lg {
            .path-label {
                font-size: 80% !important;
                @apply fill-onsurface/20 #{!important};
                text-anchor: unset;
                dominant-baseline: unset;
            }
        }
        &.font-xl {
            .path-label {
                font-size: 100% !important;
                @apply fill-onsurface/10 #{!important};
                text-anchor: unset;
                dominant-baseline: unset;
            }
        }

        circle {
            stroke-opacity: 0.9 !important;
            @apply stroke-nonecol #{!important};
        }
        &.none {
            circle {
                @apply stroke-transparent #{!important};
            }
        }
        &.low {
            circle {
                @apply stroke-low #{!important};
            }
        }
        &.medium {
            circle {
                @apply stroke-medium #{!important};
            }
        }
        &.high {
            circle {
                @apply stroke-high #{!important};
            }
        }
        &.critical {
            circle {
                @apply stroke-critical #{!important};
            }
        }
    }
}
