@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/Material_Icons.woff2') format('woff2');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('/assets/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('/assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('/assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url('/assets/fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('/assets/fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url('/assets/fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('/assets/fonts/Roboto_Condensed/RobotoCondensed-Light.ttf') format('truetype');
}
@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/assets/fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Condensed';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('/assets/fonts/Roboto_Condensed/RobotoCondensed-LightItalic.ttf') format('truetype');
}
@font-face {
    font-family: 'Roboto Condensed';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/Roboto_Condensed/RobotoCondensed-Italic.ttf') format('truetype');
}
@font-face {
    font-family: 'Roboto Condensed';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('/assets/fonts/Roboto_Condensed/RobotoCondensed-BoldItalic.ttf') format('truetype');
}
@font-face {
    font-family: 'Assets';
    font-display: swap;
    src: url('/assets/fonts/assets.ttf') format('truetype');
}
@font-face {
    font-family: 'Levels';
    font-display: swap;
    src: url('/assets/fonts/Levels.ttf') format('truetype');
}
