.shepherd-element {
    @apply rounded bg-background overflow-hidden border border-primary-500 #{!important};
    .shepherd-content {
        @apply p-2 rounded bg-transparent overflow-hidden #{!important};
        .shepherd-header {
            @apply p-1 bg-background #{!important};
        }
        .shepherd-footer {
            @apply p-1 bg-background #{!important};
        }

        .shepherd-title {
            @apply uppercase text-onbackground text-sm p-0 #{!important};
        }
        .shepherd-text {
            @apply bg-surface rounded overflow-hidden text-onsurface text-sm p-0 #{!important};
        }
    }
}

.shepherd-theme-custom {
    min-width: 600px !important;
}
