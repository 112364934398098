.e-control,
.e-tab .e-toolbar-items .e-toolbar-item .e-tab-text,
.e-card {
    font-family: Roboto;
}

.e-breadcrumb .e-breadcrumb-item[data-active-item] .e-breadcrumb-text {
    @apply text-onprimary text-sm px-1;
}

.e-breadcrumb-text,
.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text {
    @apply text-onprimary/75;
}

.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text:hover,
.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text:focus {
    @apply text-onprimary;
}

#treegridAssets.e-treegrid {
    .e-toolbar {
        .e-overlay {
            @apply opacity-100;

            .e-tbar-btn-text {
                @apply text-onsurface;
            }
        }
    }
}

.e-grid {
    @apply border-none;

    .e-spinner-pane {
        display: none;
    }

    .e-content {
        @apply bg-surface text-onsurface;
    }

    .e-toolbar {
        @apply border-surface;

        .e-icons:not(.e-btn-icon) {
            @apply text-onsecondary;
        }
    }

    .e-ccdlg {
        .e-footer-content {
            @apply border-onsurface/50;
        }

        .e-cc-searchdiv,
        .e-cc-searchdiv span.e-ccsearch-icon.e-icons {
            @apply opacity-100;
        }
    }
}

.e-grid .e-icons:not(.e-btn-icon) {
    @apply text-onsurface;
}

.e-grid .e-table {
    @apply bg-surface text-onsurface border-collapse;
}

.e-grid .e-headercell,
.e-grid .e-detailheadercell {
    @apply bg-primary-500 text-onprimary;
}

.e-grid .e-gridheader {
    @apply bg-primary-500 text-onprimary border-t-0 border-b-0;
}

.e-grid .e-rowcell {
    @apply pt-0 pb-0;
}

.e-grid .e-headercontent {
    @apply border-background;
}

.e-grid.e-resize-lines .e-headercell .e-rhandler,
.e-grid.e-resize-lines .e-headercell .e-rsuppress {
    @apply border-background;
}

.e-grid .e-rowcell:not(.e-editedbatchcell):not(.e-updatedtd),
.e-grid .e-detailrowcollapse:not(.e-editedbatchcell):not(.e-updatedtd),
.e-grid .e-detailrowexpand:not(.e-editedbatchcell):not(.e-updatedtd),
.e-grid .e-gridcontent .e-rowdragdrop:not(.e-editedbatchcell):not(.e-updatedtd),
.e-grid .e-gridheader .e-rowdragdrop:not(.e-editedbatchcell):not(.e-updatedtd),
.e-grid .e-emptyrow:not(.e-editedbatchcell):not(.e-updatedtd) {
    @apply text-onsurface;
}

.e-grid .e-rowcell,
.e-grid .e-groupcaption,
.e-grid .e-indentcell,
.e-grid .e-recordplusexpand,
.e-grid .e-recordpluscollapse,
.e-grid .e-rowdragdropcell,
.e-grid .e-detailrowcollapse,
.e-grid .e-detailrowexpand,
.e-grid .e-detailindentcell {
    @apply border-nonecol;
}

.e-grid {

    .e-detailcell,
    .e-detailindentcell {
        @apply border-transparent border-none;
    }
}

.e-grid td.e-cellselectionbackground {
    @apply bg-primary-400/50 text-onprimary;
}

.e-grid.e-gridhover tr[role='row']:not(.e-editedrow):hover .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
.e-grid.e-gridhover tr[role='row']:hover .e-detailrowcollapse:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
.e-grid.e-gridhover tr[role='row']:hover .e-rowdragdrop:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
.e-grid.e-rtl .e-gridhover tr[role='row']:hover .e-rowdragdrop:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
.e-grid.e-gridhover tr[role='row']:hover .e-detailrowexpand:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell) {
    @apply bg-primary-200/50 text-onprimarylight;
}

.e-grid td.e-active {
    @apply bg-primary-200/50 text-onprimarylight;
}

.e-grid th.e-headercell[aria-sort='ascending'] .e-headertext,
.e-grid th.e-headercell[aria-sort='descending'] .e-headertext,
.e-grid th.e-headercell[aria-sort='ascending'] .e-sortfilterdiv,
.e-grid th.e-headercell[aria-sort='descending'] .e-sortfilterdiv {
    @apply text-onprimary;
}

.e-grid .e-gridheader .e-icons:not(.e-icon-hide):not(.e-check):not(.e-stop):not(.e-icon-reorderuparrow):not(.e-icon-reorderdownarrow) {
    @apply text-secondary-500;
}

.e-grid .e-sortnumber {
    @apply bg-surface text-onsurface;
}

.e-toolbar {
    @apply bg-background text-onbackground;
}

.e-toolbar .e-toolbar-items {
    @apply bg-surface;
}

.e-toolbar .e-tbar-btn {
    @apply bg-surface text-onsurface;
}

// .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
//     @apply bg-transparent border border-secondary-500 rounded text-onsecondary;
// }

.e-toolbar .e-tbar-btn:hover,
.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn:hover .e-tbar-btn-text,
.e-toolbar .e-tbar-btn:hover.e-btn {
    @apply bg-secondary-500 rounded text-onsecondary;
}

.e-toolbar .e-tbar-btn:hover.e-btn .e-icons.bg-primary-500 {
    @apply bg-onsecondary;
}

.e-toolbar .e-tbar-btn.e-btn .e-icons {
    @apply text-primary-500;
}

.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
    @apply text-onsurface;
}

.e-dialog,
.e-dlg-content {
    @apply bg-surface text-onsurface;
}

.e-dlg-header,
.e-dlg-header * {
    @apply text-onprimary;
}

.e-dlg-header-content {
    @apply bg-primary-500 text-onprimary;
}

.e-footer-content {
    @apply bg-surface text-onsurface;
}

.e-footer-content .e-btn.e-primary.e-flat:not([DISABLED]) {
    @apply bg-secondary-500 text-onsecondary border-secondary-500;
}

.e-footer-content .e-btn:hover.e-primary.e-flat:not([DISABLED]) {
    @apply bg-secondary-600 border-secondary-600;
}

.e-footer-content .e-btn.e-flat:not([DISABLED]) {
    @apply text-white bg-red-400 border-red-400;
}

.e-footer-content .e-btn:hover.e-flat:not([DISABLED]) {
    @apply bg-red-600 border-red-600;
}

.e-hscroll:not(.e-scroll-device) .e-scroll-nav:hover {
    @apply bg-secondary-600;
}

.e-hscroll .e-scroll-nav.e-scroll-left-nav {
    @apply border-r-onsurface/50;
}

.e-hscroll .e-scroll-nav.e-scroll-right-nav {
    @apply border-l-onsurface/50;
}

.e-hscroll .e-scroll-nav {
    @apply bg-secondary-500 text-onsecondary;
}

.e-input-group:not(.e-disabled) .e-input-group-icon:hover,
.e-input-group.e-control-wrapper:not(.e-disabled) .e-input-group-icon:hover {
    @apply text-onprimary/75;
}

.e-input-group:not(.e-disabled) .e-control.e-dropdownlist~.e-ddl-icon:active,
.e-input-group:not(.e-disabled) .e-control.e-dropdownlist~.e-ddl-icon:hover,
.e-input-group:not(.e-disabled) .e-back-icon:active,
.e-input-group:not(.e-disabled) .e-back-icon:hover,
.e-popup.e-ddl .e-input-group:not(.e-disabled) .e-clear-icon:active,
.e-popup.e-ddl .e-input-group:not(.e-disabled) .e-clear-icon:hover {
    @apply bg-primary-500;
}

.e-input-group,
.e-input-group.e-control-wrapper,
.e-float-input,
.e-float-input.e-input-group,
.e-float-input.e-control-wrapper,
.e-float-input.e-input-group.e-control-wrapper {
    @apply bg-surface text-onsurface;
}

.e-input-group:not(.e-success):not(.e-warning):not(.e-error),
.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) {
    @apply border-primary-500/50;
}

.e-input-group.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-input-group.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-float-input.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-float-input.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
.e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
.e-float-input.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]),
.e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]) {
    @apply border-secondary-500;
}

.e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error),
.e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
    @apply border-secondary-500 shadow-none;
}

.e-input-group .e-input-group-icon,
.e-input-group.e-control-wrapper .e-input-group-icon {
    @apply bg-primary-500;
}

.e-checkbox-wrapper .e-label,
.e-css.e-checkbox-wrapper .e-label {
    @apply text-onsurface;
}

.e-checkbox-wrapper:hover .e-label,
.e-css.e-checkbox-wrapper:hover .e-label {
    @apply text-onsurface/75;
}

.e-checkbox-wrapper .e-frame.e-stop,
.e-css.e-checkbox-wrapper .e-frame.e-stop {
    @apply bg-highlight-400 border-highlight-400 text-onhighlight;
}

.e-checkbox-wrapper:hover .e-frame.e-stop,
.e-css.e-checkbox-wrapper:hover .e-frame.e-stop {
    @apply bg-highlight-500 border-highlight-500 text-onhighlight;
}

.e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
    @apply bg-highlight-400 border-highlight-400 text-onhighlight;
}

.e-checkbox-wrapper:hover .e-frame.e-check,
.e-css.e-checkbox-wrapper:hover .e-frame.e-check {
    @apply bg-highlight-500 border-highlight-500 text-onhighlight;
}

.e-checkbox-wrapper .e-frame,
.e-css.e-checkbox-wrapper .e-frame {
    @apply bg-surface border-onsurface;
}

.e-checkbox-wrapper:hover .e-frame,
.e-css.e-checkbox-wrapper:hover .e-frame {
    @apply bg-onsurface/50 border-onsurface;
}

.e-gantt-toolbar {
    @apply bg-surface;
}

.e-gantt {

    .e-grid .e-headercell,
    .e-grid .e-detailheadercell {
        @apply bg-nonecol text-onsurface;
    }
}

.e-detailrow .e-detailcell,
.e-detailrow .e-detailindentcell {
    @apply border-primary-100;
}

.e-row.pseudo-event {
    @apply bg-background/50;
}

.e-row.whitelisted {
    background: repeating-linear-gradient(45deg,
            transparent,
            transparent 10px,
            rgb(var(--background) / 0.25) 10px,
            rgb(var(--background) / 0.25) 20px);
}

.e-splitter.e-splitter-horizontal,
.e-splitter.e-splitter-vertical {
    @apply border-none;
}

.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover,
.e-splitter .e-split-bar.e-split-bar-horizontal.e-illi-bar-active {
    @apply bg-highlight-500 border-l-highlight-500 border-r-highlight-500;
}

.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover,
.e-splitter .e-split-bar.e-split-bar-vertical.e-ilit-bar-active {
    @apply bg-highlight-500 border-t-highlight-500 border-b-highlight-500;
}

.e-splitter .e-split-bar.e-split-bar-horizontal.e-last-bar:not(.e-resizable-split-bar) {
    @apply mx-0;
}

.e-splitter .e-split-bar.e-split-bar-vertical.e-last-bar:not(.e-resizable-split-bar) {
    @apply my-0;
}

.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover .e-navigate-arrow.e-arrow-left,
.e-splitter .e-split-bar.e-split-bdr-korizoiiLal.e-split-bar-hover .e-navigate-arrow.e-arrow-right,
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active .e-navigate-arrow.e-arrow-left,
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active .e-navigate-arrow.e-arrow-right {
    @apply bg-secondary-500 border-secondary-500;
}

.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover .e-navigate-arrow.e-arrow-left,
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover .e-navigate-arrow.e-arrow-right,
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active .e-navigate-arrow.e-arrow-left,
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active .e-navigate-arrow.e-arrow-right {
    @apply bg-secondary-500 border-secondary-500;
}

.e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow:not(.e-icon-hidden)::before,
.e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow:not(.e-icon-hidden)::after {
    @apply bg-onsecondary;
}

.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover.e-split-bar-hover .e-navigate-arrow::before,
.e-splittel active .e-navigate-arrow::before,
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active.e-split-bar-hover .e-navigate-arrow::before,
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active.e-split-bar-active .e-navigate-arrow::before {
    @apply bg-onsecondary;
}

.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover.e-split-bar-hover .e-navigate-arrow::after,
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover.e-split-bar-active .e-navigate-arrow::after,
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active.e-split-bar-hover .e-navigate-arrow::after,
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active.e-split-bar-active .e-navigate-arrow::after {
    @apply bg-onsecondary;
}

.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover .e-navigate-arrow.e-arrow-up,
.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover .e-navigate-arrow.e-arrow-down,
.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active .e-navigate-arrow.e-arrow-up,
.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active .e-navigate-arrow.e-arrow-down {
    @apply bg-secondary-500 border-secondary-500;
}

.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow::before,
.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow::after {
    @apply bg-onsecondary;
}

.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover .e-navigate-arrow.e-arrow-up::before,
me-splitter .e-pLit-bar.e-split-bar-vertical.e-split-bar-active .e-navigate-arrow.e-arrow-u•::before {
    @apply bg-onsecondary;
}

.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover .e-navigate-arrow.e-arrow-up::after,
.e-spLiLLer .e-split-bar.e-split-bar-vertical.e-split-bar-active .e-navigate-arrow.e-arrow-up::after {
    @apply bg-onsecondary;
}

.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover .e-navigate-arrow.e-arrow-up,
.e-splitter .e-split-bai.e-split-bdi-vdiLILdL.d-split-bar-hover .e-navigate-arrow.e-arrow-down,
.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active .e-navigate-arrow.e-arrow-up,
.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active .e-navigate-arrow.e-arrow-down {
    @apply bg-secondary-500 border-secondary-500;
}

.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow::before,
.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow::after {
    @apply bg-onsecondary;
}

.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover .e-navigate-arrow.e-arrow-down::before,
.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active .e-navigate-arrow.e-arrow-down::before {
    @apply bg-onsecondary;
}

.e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover .e-navigate-arrow.e-arrow-down::after,
.e-split-bar.e-split-bar-vertical.e-split-bar-active .e-navigate-arrow.e-arrow-down::after {
    @apply bg-onsecondary;
}

.e-accordion {
    @apply bg-surface border-surface text-onsurface;
}

.e-accordion .e-acrdn-item {
    @apply border-b-onsurface;
}

.e-accordion .e-active {
    @apply bg-surface;
}

.e-accordion .e-acrdn-item.e-selected.e-select,
.e-active {
    @apply border-b-onsurface border-t-onsurface;
}

.e-accordion .e-acrdn-item .e-acrdn-header:focus {
    @apply bg-surface/75;
}

.e-accordion .e-acrdn-item .e-acrdn-header:hover {
    @apply bg-surface/75;
}

.e-accordion .e-acrdn-item.e-selected.e-select.e-expand-state>.e-acrdn-header:focus {
    @apply bg-surface/75;
}

.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state>.e-acrdn-header,
.e-accordion .e-acrdn-item.e-select.e-expand-state>.e-acrdn-header {
    @apply bg-surface;
}

.e-accordion .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content,
.e-accordion .e-acrdn-item.e-selected>.e-acrdn-panel .e-acrdn-content {
    @apply text-onsurface;
}

.e-accordion.no-padding {
    .e-acrdn-content {
        @apply p-0 #{!important};
    }

    .e-acrdn-header {
        @apply p-0 #{!important};
    }

    .e-acrdn-item .e-acrdn-header {
        @apply leading-tight min-h-fit #{!important};
    }

    .e-toggle-icon {
        @apply h-fit min-h-fit #{!important};
    }
}

.e-accordion.no-padding-x {
    .e-acrdn-content {
        @apply px-0 #{!important};
    }
}

.e-accordion.no-padding-y {
    .e-acrdn-content {
        @apply py-0 #{!important};
    }
}

.e-accordion.custom {
    @apply bg-transparent border-0 #{!important};

    &.custom-p-1 {
        @apply p-1 #{!important};
    }

    &.custom-p-2 {
        @apply px-2 py-1 #{!important};
    }

    .e-acrdn-header-content {
        @apply w-full #{!important};
    }

    .e-acrdn-item {
        @apply border-0 #{!important};
    }

    .e-active,
    .e-acrdn-item.e-select.e-selected.e-expand-state>.e-acrdn-header,
    .e-acrdn-item.e-select.e-expand-state>.e-acrdn-header,
    .e-acrdn-item .e-acrdn-header:focus {
        @apply bg-transparent #{!important};
    }

    .e-tgl-collapse-icon .e-icons .e-expand-icon,
    .e-acrdn-item.e-select.e-selected.e-expand-state>.e-acrdn-header>.e-toggle-icon,
    .e-acrdn-item.e-select.e-expand-state>.e-acrdn-header>.e-toggle-icon {
        @apply text-onsurface #{!important};
    }
}

.e-input:focus:not(.e-success):not(.e-warning):not(.e-error),
.e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus,
.e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus,
.e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus,
.e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus,
.e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input,
.e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input {
    @apply border-highlight-500 shadow-none;
}

.e-input-group .e-input-group-icon,
.e-input-group.e-control-wrapper .e-input-group-icon {
    @apply text-onprimary;
}

.e-ddl.e-popup {
    @apply bg-surface border-primary-500/50;
}

.e-dropdownbase .e-list-item {
    @apply bg-surface text-onsurface;
}

.e-dropdownbase .e-list-item.e-active,
.e-dropdownbase .e-list-item.e-active.e-hover {
    @apply bg-secondary-400/75 text-onsecondary border-secondary-400;
}

.e-dropdownbase .e-list-item:hover {
    @apply bg-secondary-400/50 text-onsecondary border-secondary-400;
}

.e-dropdownbase .e-list-item.e-item-focus {
    @apply bg-secondary-400/50 text-onsecondary border-secondary-400;
}

.e-radio+label .e-label {
    @apply text-onbackground text-xs;
}

.e-treeview .e-list-text {
    @apply text-onsurface;
}

.e-treeview.columnsTreeView {
    .e-text-content {
        @apply p-0;
    }

    .e-list-text {
        @apply w-full;
    }

    .e-list-item.e-level-1>.e-fullrow {
        @apply bg-background;
    }

    .e-icons.e-icon-collapsible.interaction {
        @apply hidden;
    }

    @apply w-full h-full;

    >h6 {
        @apply capitalize text-base;
    }
}

.e-treeview .e-list-item.e-hover>.e-fullrow,
.e-treeview .e-list-item.e-hover.e-active>.e-fullrow,
.e-treeview .e-list-item.e-active>.e-fullrow {
    @apply bg-transparent border-0;
}

.e-treeview .e-list-item.e-active>.e-text-content .e-list-text,
.e-treeview .e-list-item.e-hover>.e-text-content .e-list-text {
    @apply text-onsurface;
}

.e-chip-list.e-chip.e-outline,
.e-chip-list .e-chip.e-outline {
    @apply bg-surface text-onsurface border-primary-500 rounded-full;
}

.e-btn-group .e-btn:active,
.e-btn-group input:active+label.e-btn,
.e-btn-group input:checked+label.e-btn {
    @apply bg-secondary-400/75 border-secondary-400;
}

.e-container.e-color-palette {
    @apply shadow-none bg-transparent #{!important};
}

.e-custom-palette {
    @apply bg-surface border-2 rounded-full border-primary-100 m-0 p-0;
}

.e-custom-palette>.e-palette {
    @apply px-1 py-0 #{!important};
}

.e-custom-palette .e-selected {
    @apply leading-7 text-white;
}

.e-custom-palette .e-selected::before {
    @apply font-bold;
    content: '✓';
}

ejs-diagram {
    svg {
        g {
            text {
                @apply fill-onsurface;
            }
        }
    }
}

rect#eventsMap_MapBorder {
    fill: transparent !important;
}

g#eventsMap_Zooming_KitCollection {
    opacity: 1 !important;
}

ejs-treegrid {
    .e-content {
        overflow-y: auto !important;
    }
}

.e-treecolumn-container {
    @apply flex items-center #{!important};
}

.e-gantt {
    .e-gantt-chart {
        .e-timeline-top-header-cell {
            @apply text-onbackground border border-primary-500 #{!important};
        }

        .e-timeline-header-container {
            @apply bg-background #{!important};
        }

        .e-chart-root-container {
            @apply bg-surface #{!important};
        }

        .e-active {
            @apply bg-secondary-500/25 #{!important};
        }

        .e-weekend,
        .e-weekend-header-cell {
            @apply bg-nonecol/50 #{!important};
        }
    }

    .e-gantt-tree-grid-pane {
        .e-gridcontent {
            .e-content {
                @apply overflow-y-auto overflow-x-auto #{!important};
            }
        }

        .e-grid {
            td.e-active {
                @apply bg-secondary-500/25 #{!important};
            }
        }
    }
}

.e-tooltip-wrap .e-tip-content {
    @apply bg-surface text-onsurface border border-nonecol rounded;
}

.e-split-bar.e-split-bar-vertical.e-resizable-split-bar,
.e-split-bar.e-split-bar-horizontal.e-resizable-split-bar {
    @apply bg-onsurface/25 border-0;

    .e-resize-handler {
        @apply bg-onsurface/25 text-onsurface;
    }

    .e-resize-handler::before {
        font-size: 10px;
    }
}

.e-gantt-toolbar.e-control.e-toolbar {
    @apply border-0;
}

.e-gantt .e-gantt-chart {

    .e-chart-row-border,
    .e-chart-row-border.e-lastrow {
        @apply border-nonecol;
    }

    .e-gantt-parent-taskbar-inner-div {
        @apply border-2 border-secondary-500 bg-secondary-500/50;
    }
}

.e-toolbar .e-toolbar-items .e-toolbar-item.e-overlay {
    @apply bg-transparent;
}

.e-switch-wrapper .e-switch-off,
.e-css.e-switch-wrapper .e-switch-off {
    @apply bg-nonecol/50;
}

.e-switch-wrapper .e-switch-on,
.e-css.e-switch-wrapper .e-switch-on {
    @apply bg-secondary-500/50;
}

.e-switch-wrapper:hover .e-switch-inner.e-switch-active .e-switch-on,
.e-css.e-switch-wrapper:hover .e-switch-inner.e-switch-active .e-switch-on {
    @apply bg-secondary-500;
}

.e-menu-item.e-menu-caret-icon {
    @apply flex items-center justify-center w-full h-full;
}

.e-menu-parent.e-ul {
    max-height: 200px;
    /* Hauteur maximale avant le défilement */
    overflow-y: auto;
    /* Activation du défilement vertical */
}

.e-query-builder {
    .e-rule-container {
        @apply bg-background border-nonecol;
    }

    .e-btn.e-round:hover,
    .e-css.e-btn.e-round:hover {
        @apply bg-surface/50 border-nonecol text-onsurface;
    }

    .e-btn.e-round,
    .e-css.e-btn.e-round {
        @apply bg-surface border-nonecol text-onsurface;
    }

    .e-removerule.e-btn.e-round {
        @apply bg-surface border-nonecol text-onsurface;
    }

    .e-lib.e-btn-group {
        @apply border-nonecol;
    }

    .e-btn,
    .e-css.e-btn {
        @apply bg-surface text-onsurface;
    }

    .e-btn-group .e-btn:disabled,
    .e-btn-group input:disabled+label.e-btn,
    .e-css.e-btn-group .e-btn:disabled,
    .e-css.e-btn-group input:disabled+label.e-btn {
        @apply bg-nonecol/75 border-nonecol text-onsurface;
    }

    .e-query-builder .e-btn-group input:checked+label.e-btn,
    .e-query-builder .e-btn-group .e-btn.e-qb-toggle.e-active-toggle {
        @apply bg-secondary-500;
    }

    &.notification-query-builder {
        .e-rule-field {
            @apply flex items-center;
        }
    }
}

.e-ddl.e-pop-up.e-lib.e-control.e-popup-open {
    @apply bg-red-500;
}

.e-menu-wrapper .e-menu .e-menu-item .e-caret,
.e-menu-container .e-menu .e-menu-item .e-caret {
    @apply text-onsurface;
}

.e-menu-wrapper .e-menu .e-menu-item,
.e-menu-container .e-menu .e-menu-item {
    @apply bg-surface text-onsurface;

    &.e-focused {
        @apply bg-surface/75 text-onsurface;
    }
}

.e-menu-wrapper .e-menu .e-menu-item.e-selected,
.e-menu-container .e-menu .e-menu-item.e-selected {
    @apply bg-surface/75 text-onsurface;
}

.e-menu-wrapper .e-ul .e-menu-item .e-menu-url {
    @apply bg-surface text-onsurface;
}

.e-menu-wrapper .e-ul .e-menu-item.e-focused,
.e-menu-container .e-ul .e-menu-item.e-focused {
    @apply bg-background text-onsurface;
}

.e-menu-parent.e-ul {
    @apply bg-surface text-onsurface;
}

.e-menu-wrapper.e-popup.e-menu-popup,
.e-menu-container.e-popup.e-menu-popup {
    @apply border border-nonecol;
}

#scatterPlot {
    height: 100% !important;
}

#eventCategories {
    .e-list-item.e-level-1.e-checklist {
        @apply p-0 h-auto;
    }
}

*:not(ejs-tab)>.e-toolbar {
    .e-toolbar-items {
        @apply w-full flex items-center justify-center overflow-hidden;
    }
}

.e-tab {
    .e-tab-header {
        .e-indicator {
            @apply bg-secondary-500;
        }

        .e-toolbar-item {
            &.e-active {
                .e-tab-text {
                    @apply text-secondary-500;
                }

                .e-tab-wrap:hover {
                    .e-tab-text {
                        @apply text-secondary-500;
                    }
                }
            }

            .e-tab-wrap:hover {
                .e-tab-text {
                    @apply text-secondary-500;
                }
            }
        }
    }
}