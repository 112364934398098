.cdk-row {
    .cdk-virtual-scroll-content-wrapper {
        @apply flex;
    }
}

.cdk-col {
    .cdk-virtual-scroll-content-wrapper {
        @apply flex flex-col;
    }
}
