form {

    select,
    textarea,
    input {
        @apply block px-1 text-sm text-onsurface bg-surface rounded-lg border border-primary-500;

        &[disabled],
        &[readOnly] {
            @apply bg-nonecol/75 border border-onsurface;
        }
    }

    label {
        @apply uppercase pr-1 whitespace-nowrap;
    }
}