.apexcharts-tooltip.apexcharts-theme-light {
    @apply bg-surface text-onsurface border-2 border-background #{!important};
    .apexcharts-tooltip-title {
        @apply bg-background text-onbackground border-b-background #{!important};
    }
}

.custom-chart {
    .apexcharts-xaxis-tick {
        @apply stroke-transparent #{!important};
    }

    .apexcharts-xaxis.apexcharts-yaxis-inversed {
        line {
            @apply stroke-transparent #{!important};
        }
    }

    .apexcharts-yaxis.apexcharts-xaxis-inversed {
        line {
            @apply stroke-transparent #{!important};
        }
    }
}

.apexcharts-point-annotation-label {
    text-shadow: 1px 1px 1px rgba(var(--none-criticity));
}
