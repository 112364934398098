@mixin size($width, $height: $width) {
    width: $width !important;
    height: $height !important;
}

@mixin iconmask($url) {
    mask: url($url) center;
    mask-size: contain;
    mask-repeat: no-repeat;
}
