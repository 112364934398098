gridster {
    padding: 0px !important;
    overflow: unset !important;
    overflow-y: auto !important;
    background-color: transparent !important;

    gridster-item {
        background-color: transparent;
        overflow: unset !important;
    }

    .gridster-row {
        border-top: 1px dotted getColor('title') !important;
        border-bottom: 1px dotted getColor('title') !important;
    }
    .gridster-column {
        border-left: 1px dotted getColor('title') !important;
        border-right: 1px dotted getColor('title') !important;
    }
}

.gridster-preview {
    background-color: getColor('primary01');
    border: 1px dotted getColor('primary02');
    border-radius: 5px;
}

.edit-mode {
    .drag-handler {
        cursor: move;
    }
}
